<template>
  <FocusTrap>
    <div  id="mycard" class="card">
    <div id="toolbar" class="fixed-table-toolbar">
      <div class="float-left">
        <h4 style="padding-left: 10px; padding-top: 10px; padding-bottom: 0px;"> <span class="icon-list"></span> &nbsp; Fuel Purchase List</h4>
      </div>
      <div class="float-right search">
        <div class="form-group row">
          <button type="button" class="btn btn-outline alpha-success text-success-800 border-success-600 legitRipple col-md-4" @click="showFilterWindow" ><i class="icon-filter4 ml-2"></i></button>
          <input  id="txtsearch" class="form-control col-md-8" type="text" placeholder="Search" autocomplete="off">
        </div>

      </div>
    </div>

    <div class="table-responsive">
      <table id="invoice_list_table"
             class="table table-columned table-hover"
             data-search="false"
             data-show-refresh="false"
             data-show-columns="false"
             data-pagination="false"
             data-page-list="[10, 25, 50, 100, ALL]"
             data-toggle="context"
             data-row-style="rowStyle"
             data-show-footer="false"
             data-row-attributes="rowAttributes"
             data-target=".context-table">
        <thead>
          <tr>
            <th data-field="id" data-class="d-none">id</th>
            <th data-field="status" data-class="d-none">Status</th>
            <th data-formatter="runningFormatter" data-width="75" >S.No</th>
            <th data-field="doc_date" data-width="110" data-formatter="dateFormatter" data-sortable="true">Date</th>
            <th data-field="doc_no" data-width="100" data-sortable="true">Doc No</th>
            <th data-field="ref_no" data-width="100" data-sortable="true">Inv No</th>
            <th data-field="ref_date" data-width="110" data-formatter="dateFormatter" data-sortable="true">Inv Date</th>
            <th data-field="net_amount" data-width="100" data-align="right" data-formatter="indianFormat" data-sortable="true">Amount</th>
            <th data-field="vehicleno" data-width="100" data-sortable="true">Vehicle No</th>
            <th data-field="acc_id" data-width="50" data-sortable="true">Acc ID</th>
            <th data-field="remarks" data-sortable="true">Remarks</th>
          </tr>
        </thead>
        <tbody>

        </tbody>
        <tfoot>
        </tfoot>
      </table>
    </div>

    <!-- Context Menu -->
    <div id="context-menu" class="context-table context-submenu">
      <div class="dropdown-menu">
        <a href="#" class="dropdown-item" @click="loadData"><i class="icon-reset" style="color: darkgreen"></i>Refresh</a>
        <a href="#" class="dropdown-item" ><i class="icon-file-eye2" ></i>Preview</a>

        <div class="dropdown-divider"></div>
        <a href="#" class="dropdown-item" style="color: blue;"> <i class="icon-pencil3" ></i>Modify Purchase</a>
        <div class="dropdown-divider"></div>
        <a href="#" class="dropdown-item" > <i class="icon-database" ></i>Export to Accounts</a>
        <div class="dropdown-divider"></div>

        <a href="#" class="dropdown-item" style="color: red"> <i class="icon-blocked" ></i>Cancel</a>


      </div>
    </div>
    <!-- End of Context Menu -->

    <vmodal name="fuleinvoice_window" transition="nice-modal-fade" :delay="100" :resizable="true" width="90%" height="80%" @before-open="beforeOpen" @before-close="beforeClose" >
      <InvoiceForm v-bind:myvoucher="invoice" v-on:invoice_saved="closeModal" ></InvoiceForm>
    </vmodal>

    <div class="modal" id="payment_modal"  >
      <div class="modal-dialog  modal-full modal-dialog-centered modal-dialog-scrollable" >
        <div class="modal-content" >

          <!-- Modal body -->
          <div class="modal-body" >
<!--            <PaymentForm v-if="invoice.code > 1" v-bind:invoice="invoice" v-on:payment_updated="onPaymentUpdate" > </PaymentForm>-->
          </div>

        </div>
      </div>
    </div>

    <div class="modal" id="previewmodal"  >
      <div class="modal-dialog  modal-full modal-dialog-centered modal-dialog-scrollable" >
        <div class="modal-content" >

          <!-- Modal body -->
          <div class="modal-body" >
<!--            <PurchaseInvoicePreview v-if="invoice.code > 1" v-bind:invoice="invoice" > </PurchaseInvoicePreview>-->
          </div>

        </div>
      </div>
    </div>

    <div class="modal" id="voucherpreview_modal"  >
      <div class="modal-dialog  modal-full modal-dialog-centered modal-dialog-scrollable" >
        <div class="modal-content" >

          <!-- Modal body -->
          <div class="modal-body" >
            <VoucherPreviewForm v-if="voucher.id > 1" v-bind:myvoucher="voucher" > </VoucherPreviewForm>
          </div>

        </div>
      </div>
    </div>


      <vmodal name="filter-window" transition="nice-modal-fade" :delay="100" :resizable="true" width="42%" height="200" @opened="filter_opened" >
        <FocusTrap>
          <form>
          <div class="card">
            <div class="card-header header-elements-inline bg-transparent" style="padding: 10px;background-color: whitesmoke;">
              <h3 class="card-title">Filter</h3>
              <div class="header-elements">
                <div class="list-icons">
                  <!--<a class="list-icons-item" data-action="remove" @click="closeRequest"></a>-->
                </div>
              </div>
            </div>

            <div class="card-body">

              <p/>

              <div class="form-group row">
                <label class="col-md-2 col-form-label">From:</label>
                <div class="col-md-4">
                  <input  id="txtfrom" type="date" class="form-control text-center"  v-model="from_date" :max="upto_date" />
                </div>

                <label class="col-md-2 col-form-label">Upto</label>
                <div class="col-md-4">
                  <input type="date" class="form-control text-center"  v-model="upto_date" :min="from_date" />
                </div>
              </div>

              <div class="text-right">
                <button type="button" class="btn btn-outline alpha-success text-success-800 border-success-600 legitRipple" @click="loadData" >Show Data<i class="icon-database-refresh ml-2"></i></button>
              </div>

            </div>
          </div>
        </form>
        </FocusTrap>
      </vmodal>


    <vmodal name="ibr-move-window" transition="nice-modal-fade" :delay="100" :resizable="true" :width="250" height="300" >
      <!-- Form -->
      <form class="modal-body form-validate" action="#">
        <div class="text-center mb-3">
          <i class="icon-reading icon-2x text-slate-300 border-slate-300 border-3 rounded-round p-3 mb-3 mt-1"></i>
          <h5 class="mb-0">Choose Branch to be Move</h5>
        </div>

        <div class="form-group form-group-feedback form-group-feedback-left">
          <select class="form-control" v-model="selected_ibr">
            <option v-for="brn in branches" v-bind:value="brn.code">
              {{ brn.name }}
            </option>
          </select>
          <div class="form-control-feedback">
            <i class="icon-box text-muted"></i>
          </div>
        </div>

        <div class="form-group">
          <button type="button" class="btn btn-outline alpha-success text-success-800 border-success-600 legitRipple btn-block" >Move <i class="icon-move-right ml-2"></i></button>
        </div>

      </form>
      <!-- /form -->

    </vmodal>
  </div>
  </FocusTrap>
</template>

<script>
import InvoiceForm from '@/views/xpos/fuelpurchase/FuelPurchaseForm.vue'
import FabButton from '@/components/core/FabButton.vue'
import moment from 'moment-timezone'
import { userService } from '@/store/auth-header.js'
import { store } from '@/store/store.js'
export default {
  name: 'FuelPurchaseView',
  components: {
    InvoiceForm,
    FabButton
  },
  store,
  data () {
    return {
      mytable: {},
      voucher: JSON.parse('{"com_id":0,"type":0,"status":0,"finyear":0,"vchtype":0,"doc_date":"0001-01-01","doc_no":0,"ref_no":"","ref_date":"0001-01-01","ledger":{"id":2,"status":0,"type":0,"name":"","alias":"","print_name":"","group":{"id":0,"status":0,"type":0,"name":"","alias":"","print_name":"","parent_code":0,"nature":0,"affect_profit":false,"behave_like_sub_ledger":false,"net_cr_dr_balance":false,"used_for_calculation":false,"system_object":false},"opening_balance":0,"print_order":0,"gstin":"","address":{"name":"","street":"","city":"","pincode":"","state_code":0,"email":"","telephone":"","mobile":""},"system_object":false},"amount":0,"remarks":"","details":[]}'),
      invoice: JSON.parse('{"id":0,"ver":0,"status":0,"is_cr":"Y","type":0,"finyear":0,"series":302,"doc_date":"0001-01-01","doc_no":0,"ref_no":"","ref_date":"0001-01-01","ledger":{"id":0,"type":0,"group_code":0,"name":"","print_name":"","street":"","city":"","pin":"","state_id":0,"gstin":"","contact_person":"","telephone":"","email":""},"eway_no":"","eway_date":"0001-01-01","vehicle_no":"","process":{"id":0,"name":"","nature":0,"p_name":"","sys_obj":false},"remarks":"","gross_amount":0,"tax_amount":0,"round_off":0,"net_amount":0,"list":[]}'),
      series: 302,
      from_date: moment().format('YYYY-MM-DD'),
      upto_date: moment().format('YYYY-MM-DD'),
      purchtype: 0,
      purchfrom: 0,
      branches: [],
      invoice_id: 0,
      selected_ibr: 1,
      ledgers: [],
      supplier_code: 0
    }
  },
  created () {
    let user = JSON.parse(localStorage.getItem('xposuser'));
    store.state.user = user;

    store.state.token = localStorage.getItem('xpostoken');
    store.state.user.finyear = 2020;

  },
  mounted () {
    let self = this;

    self.$data.from_date = moment().add(-7, 'd').format('YYYY-MM-DD')

    this.$data.mytable = $('#invoice_list_table')
    this.$data.mytable.bootstrapTable()
    $('#invoice_list_table').on('post-body.bs.table', function (e) {
      $('[data-toggle="popover"]').popover()
    })

    this.$data.mytable.contextmenu({
      target: '#context-menu',
      scopes: 'tbody > tr',
      onItem: function (row, e) {
        let id = $(row.children('*')[0]).text()
        let status = parseInt($(row.children('*')[1]).text())

        if ($(e.target).text() === 'Modify Purchase') {
          // if (status === 0) {
          self.modifyDocument(id)
          // }
        } else if ($(e.target).text() === 'Cancel') {
          // if(status === 0) {
          self.removeDocument(id, status)
          // }
        } else if ($(e.target).text() === 'Payment') {
          self.invoicePayment(id, status)
        } else if ($(e.target).text() == 'Preview') {
          self.invoicePreview(id)
        } else if ($(e.target).text() == 'Export to Accounts') {
          // self.exportInvoice(parseInt($(row.children('*')[2]).text()));
          self.exportInvoice(id)
        } else if ($(e.target).text() === 'Move') {
          self.$data.invoice_id = id
          self.$modal.show('ibr-move-window')
        }
      }
    })

    $('#txtsearch').keyup(function () {
      let val = $.trim($(this).val()).replace(/ +/g, ' ').toLowerCase()
      $('#invoice_list_table>tbody>tr').show().filter(function () {
        let text = $(this).text().replace(/\s+/g, ' ').toLowerCase()
        return !~text.indexOf(val)
      }).hide()
    })


    self.loadSuppliers();

    $('.context-submenu').contextmenu({
      target: '.dropdown-menu'
    }).on('hidden.bs.context', function () {
      $(this).find('.context-sub-menu .show').removeClass('show')
    })

    self.$modal.show('filter-window');
  },
  methods: {
    showFilterWindow () {
      this.$modal.show('filter-window');

    },
    closeModal () {
      this.$modal.hide('fuleinvoice_window');
    },
    beforeOpen () {

    },
    beforeClose () {
      // this.$data.invoice = JSON.parse('{"id":0,"ver":0,"status":0,"is_cr":"Y","type":0,"finyear":2018,"series":302,"doc_date":"0001-01-01","doc_no":0,"ref_no":"","ref_date":"0001-01-01","ledger":{"id":0,"type":0,"group_code":0,"name":"","print_name":"","street":"","city":"","pin":"","state_id":0,"gstin":"","contact_person":"","telephone":"","email":""},"eway_no":"","eway_date":"0001-01-01","vehicle_no":"","process":{"id":0,"name":"","nature":0,"p_name":"","sys_obj":false},"remarks":"","gross_amount":0,"tax_amount":0,"round_off":0,"net_amount":0,"list":[]}');
    },
    filter_opened(){
      $('#txtfrom').focus();
    },
    showModal () {
      this.$modal.show('fuleinvoice_window')
    },
    blockModal () {
      $('#mycard').block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      })
    },
    onPaymentUpdate () {
      this.loadData()
      $('#payment_modal').modal('hide')
    },
    voucherPreview (code) {
      let self = this

      const requestOptions = {
        method: 'GET',
        mode: 'cors',
        headers: userService.authHeader()
      }

      self.$data.voucher = JSON.parse('{"com_id":0,"type":0,"status":0,"finyear":0,"vchtype":0,"doc_date":"0001-01-01","doc_no":0,"ref_no":"","ref_date":"0001-01-01","ledger":{"id":2,"status":0,"type":0,"name":"","alias":"","print_name":"","group":{"id":0,"status":0,"type":0,"name":"","alias":"","print_name":"","parent_code":0,"nature":0,"affect_profit":false,"behave_like_sub_ledger":false,"net_cr_dr_balance":false,"used_for_calculation":false,"system_object":false},"opening_balance":0,"print_order":0,"gstin":"","address":{"name":"","street":"","city":"","pincode":"","state_code":0,"email":"","telephone":"","mobile":""},"system_object":false},"amount":0,"remarks":"","details":[]}')
      fetch(`${process.env.VUE_APP_ROOT_API}/v1/accounts/voucher/code/${code}/`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.accepted && !_.isNull(resp.data)) {
          self.$data.voucher = resp.data
          // self.$data.voucher.doc_date = moment(resp.data.doc_date).format('YYYY-MM-DD');
          // self.$data.voucher.ref_date = moment(resp.data.ref_date).format('YYYY-MM-DD');
          $('#voucherpreview_modal').modal('show')
        } else {
          swal({ title: 'Oops!', text: resp.msg, type: 'error' })
        }
      }).catch(function (err) {
        swal({ title: 'Oh noes!', text: err.toString(), type: 'error' })
      })
    },
    invoicePreview (id) {
      let self = this

      const requestOptions = {
        method: 'GET',
        mode: 'cors',
        headers: userService.authHeader()
      }

      self.$data.invoice = {}
      fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/transaction/${id}/`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.accepted) {
          self.$data.invoice = resp.data
          self.$data.invoice.doc_date = moment(resp.data.doc_date).format('YYYY-MM-DD')
          self.$data.invoice.ref_date = moment(resp.data.ref_date).format('YYYY-MM-DD')
          $('#previewmodal').modal('show')
        } else {
          swal({ title: 'Oops!', text: resp.msg, type: 'error' })
        }
      }).catch(function (err) {
        swal({ title: 'Oh noes!', text: err.toString(), type: 'error' })
      })
    },
    loadSuppliers () {
      let self = this

      const requestOptions = {
        mode: 'cors',
        headers: userService.authHeader()
      }

      // fetch the Ledgers
      self.$data.ledgers = []
      fetch(`${process.env.VUE_APP_ROOT_API}api/v1/xbunk/oilcompany/`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.accepted) {
          if (_.isArray(resp.data)) {
            self.$data.ledgers = resp.data
          }
        } else {
          swal({ title: 'Oops', text: resp.msg, type: 'error' })
        }
      }).catch(function (err) {
        swal({ title: 'Oops', text: err.toString(), type: 'error' })
      })
    },
    exportInvoice (id) {
      let self = this

      const requestOptions = {
        method: 'POST',
        mode: 'cors',
        headers: userService.authHeader()
      }

      self.blockModal()
      fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/transaction/export/${id}/`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.accepted) {
          swal({ title: 'Good!', text: resp.msg, type: 'success' })
        } else {
          swal({ title: 'Oops!', text: resp.msg, type: 'error' })
        }
      }).catch(function (err) {
        swal({ title: 'Oh noes!', text: err.toString(), type: 'error' })
      }).finally(function () {
        $('#mycard').unblock()
      })
    },
    modifyDocument (id) {
      let self = this;

      const requestOptions = {
        method: 'GET',
        mode: 'cors',
        headers: userService.authHeader()
      }

      self.$data.invoice = {};
      fetch(`${process.env.VUE_APP_ROOT_API}api/v1/xbunk/fuelpurchase/${id}/`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.accepted) {

          console.log(JSON.stringify(resp.data));
          self.$data.invoice = resp.data;
          self.$data.invoice.doc_date = moment(resp.data.doc_date).format('YYYY-MM-DD');
          self.$data.invoice.ref_date = moment(resp.data.ref_date).format('YYYY-MM-DD');

          self.$modal.show('fuleinvoice_window');
        } else {
          swal({ title: 'Oops!', text: resp.msg, type: 'error' })
        }
      }).catch(function (err) {
        swal({ title: 'Oh noes!', text: err.toString(), type: 'error' })
      })
    },
    modifyPayment (id, status) {
      const self = this

      if (status > 0) {
        const requestOptions = {
          method: 'GET',
          mode: 'cors',
          headers: userService.authHeader()
        }

        self.$data.invoice = {}
        fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/transaction/${id}/`, requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.accepted) {
            self.$data.invoice = resp.data
            self.$data.invoice.doc_date = moment(resp.data.doc_date).format('YYYY-MM-DD')
            self.$data.invoice.ref_date = moment(resp.data.ref_date).format('YYYY-MM-DD')

            self.$data.invoice.payment.pay_date = moment(resp.data.payment.pay_date).format('YYYY-MM-DD')
            self.$data.invoice.payment.clearing_date = moment(resp.data.payment.clearing_date).format('YYYY-MM-DD')

            $('#payment_modal').modal('show')
          } else {
            swal({ title: 'Oops!', text: resp.msg, type: 'error' })
          }
        }).catch(function (err) {
          swal({ title: 'Oh noes!', text: err.toString(), type: 'error' })
        })
      }
    },
    removeDocument (id) {
      let self = this

      self.$data.invoice.id = id

      const requestOptions = {
        method: 'DELETE',
        mode: 'cors',
        headers: userService.authHeader(),
        body: JSON.stringify(self.$data.invoice)
      }

      swal({
        title: 'What is the reason for cancel this transaction?',
        input: 'text',
        inputPlaceholder: 'Enter Remarks here',
        showCancelButton: true,
        inputClass: 'form-control',
        inputValidator: function (value) {
          return !value && 'You need to write something!'
        }
      }).then(function (result) {
        if (result.value) {
          fetch(`${process.env.VUE_APP_ROOT_API}api/v1/xbunk/fuelpurchase/${id}/?remarks=${result.value}`, requestOptions).then(userService.handleResponse).then(function (resp) {
            self.$modal.hide('receipt-window');
            if (resp.accepted) {
              self.loadData()
              swal({
                title: 'Canceled!',
                text: 'Your request has been processed',
                type: 'success'
              })
            } else {
              swal({ title: 'Oops', text: resp.msg, type: 'info' })
            }
          }).catch(function (err) {
            swal({ title: 'Oops', text: err.toString(), type: 'error' })
          })
        }
      })
    },
    loadData () {
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode: 'cors',
        headers: userService.authHeader()
      }

      $('#txtsearch').val('')

      $(self.$data.mytable).block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      })

      self.$data.mytable.bootstrapTable('load', [])
      self.$data.mytable.bootstrapTable('showLoading');


      //`${process.env.VUE_APP_ROOT_API}api/v1/xbunk/fuelpurchase/${store.state.user.finyear}/?ldgr=${self.$data.supplier_code}&ibr=${store.state.user.branch.code}&from=${self.$data.from_date}&upto=${self.$data.upto_date}&types=${self.$data.purchtype}&purchfrom=${self.$data.purchfrom}`

      fetch(`${process.env.VUE_APP_ROOT_API}api/v1/xbunk/fuelpurchase/finyear/${store.state.user.finyear}/?from=${self.$data.from_date}&upto=${self.$data.upto_date}`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.accepted) {
          if (!_.isNull(resp.data)) {
            self.$data.mytable.bootstrapTable('load', resp.data)
          }
        } else {
          swal({ title: 'Oops!', text: resp.msg, type: 'error' })
        }
        self.$modal.hide('filter-window');
      }).catch(function (err) {
        swal({ title: 'Oh noes!', text: err.toString(), type: 'error' })
      }).finally(function () {
        self.$data.mytable.bootstrapTable('hideLoading');
        $(self.$data.mytable).unblock();
        // $(self.$data.mytable).bootstrapTable('resetView');
      })
    },
    loadBranches() {
      const self = this;

      const requestOptions = {
        mode: 'cors',
        headers: userService.authHeader()
      }

      // fetch the Branches
      self.$data.branches = []
      fetch(`${process.env.VUE_APP_ROOT_API}api/v1/accounts/branch/`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.accepted) {
          if (_.isArray(resp.data)) {
            self.$data.branches = resp.data;
          }
        } else {
          swal({ title: 'Oops', text: resp.msg, type: 'error' })
        }
      }).catch(function (err) {
        swal({ title: 'Oops', text: err.toString(), type: 'error' })
      })
    },

  }

}
</script>

<style scoped>
input:focus {
  background: #feff00;

}

textarea:focus {
  background: #feff00;

}

select:focus {
  background: #feff00;
}

button:focus {
  background: #feff00;
  color: #0a0a0a!important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
